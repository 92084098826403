<!--  -->
<template>
    <div class=''>
        <Headers></Headers>
        <div   class="pcAtrcontent">
        <div  class="pcAtrcontent-item classical">
            <a  href="#" target="_blank" class="cate"><img  :src="require('./../../public/page3/classical_cate.1832495.jpg')" alt="古典音乐" /></a> 
            <div  class="cate-group">
            <a  href="#" target="_blank" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/Orchestral.ed60f51.jpg')" alt="管弦乐" />
            </div> 
            <div  class="cont">
            <h6  class="name">管弦乐</h6> 
            <small  class="desc">Orchestral</small> 
            <i  class="more-icon"></i>
            </div> 
            <!----></a>
            <a  href="#" target="_blank" class="cate-item">
            <!----> 
            <div  class="cont">
            <h6  class="name">协奏曲</h6> 
            <small  class="desc">Concerto</small> 
            <i  class="more-icon"></i>
            </div> 
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/Concertos.f13797b.jpg')" alt="协奏曲" />
            </div></a>
            <a  href="#" target="_blank" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/Opera.02b75e6.jpg')" alt="歌剧" />
            </div> 
            <div  class="cont">
            <h6  class="name">歌剧</h6> 
            <small  class="desc">Opera</small> 
            <i  class="more-icon"></i>
            </div> 
            <!----></a>
            <a  href="#" target="_blank" class="cate-item">
            <!----> 
            <div  class="cont">
            <h6  class="name">室内乐</h6> 
            <small  class="desc">Chamber Music</small> 
            <i  class="more-icon"></i>
            </div> 
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/ChamberMusic.b10bde4.jpg')" alt="室内乐" />
            </div></a>
            </div> 
            <div  class="clear-both"></div>
        </div>
        <div  class="pcAtrcontent-item world">
            <a  href="#" target="_blank" class="cate"><img :src="require('./../../public/page3/world_cate.93b8680.jpg')" alt="世界民族" /></a> 
            <div  class="cate-group">
            <a  href="#" target="_blank" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/img_africa.cf3d9d5.jpg')" alt="非洲文化" />
            </div> 
            <div  class="cont">
            <h6  class="name">非洲文化</h6> 
            <small  class="desc">Africa</small> 
            <i  class="more-icon"></i>
            </div> 
            <!----></a>
            <a  href="#" target="_blank" class="cate-item">
            <!----> 
            <div  class="cont">
            <h6  class="name">法国文化</h6> 
            <small  class="desc">French</small> 
            <i  class="more-icon"></i>
            </div> 
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/3.jpg')" alt="法国文化" />
            </div></a>
            <a  href="#" target="_blank" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/2.jpg')" alt="中国" />
            </div> 
            <div  class="cont">
            <h6  class="name">中国</h6> 
            <small  class="desc">China</small> 
            <i  class="more-icon"></i>
            </div> 
            <!----></a>
            <a  href="#" target="_blank" class="cate-item">
            <!----> 
            <div  class="cont">
            <h6  class="name">阿根廷</h6> 
            <small  class="desc">Argentina</small> 
            <i  class="more-icon"></i>
            </div> 
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/img_agenting.133dd80.jpg')" alt="阿根廷" />
            </div></a>
            </div> 
            <div  class="clear-both"></div>
        </div>
        <div  class="pcAtrcontent-item video">
            <a  href="#" target="_self" class="cate"><img  :src="require('./../../public/page3/1.jpg')" alt="视频" /></a> 
            <div  class="cate-group">
            <a  href="#" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/A00008939.55caaaf.jpg')"/>
            </div> 
            <div  class="cont">
            <h6  class="name">BACH, J.S.: Bach for Brass (German Brass)</h6> 
            <!----> 
            <!---->
            </div> 
            <!----></a>
            <a  href="#" target="_self" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/720409.30c60fa.jpg')" />
            </div> 
            <div  class="cont">
            <h6  class="name">BRAHMS, J.: Piano Concerto No. 1 in D Minor, Op. 15 / RACHMANINOV, S.: Symphonic Dances (Kerstmatinee 2004) (Barenboim, Jansons)</h6> 
            <!----> 
            <!---->
            </div> 
            <!----></a>
            </div> 
            <div  class="clear-both"></div>
        </div>
        <div  class="pcAtrcontent-item spoken">
            <a  href="#" target="_self" class="cate"><img  :src="require('./../../public/page3/spoken_cate.3a7b5fa.jpg')" alt="有声读物" /></a> 
            <div  class="cate-group">
            <a  href="#" target="_self" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/img_zhuanji.9727c8e.jpg')" alt="传记" />
            </div> 
            <div  class="cont">
            <h6  class="name">传记</h6> 
            <small  class="desc">Biography</small> 
            <i  class="more-icon"></i>
            </div> 
            <!----></a>
            <a  href="#" target="_self" class="cate-item">
            <!----> 
            <div  class="cont">
            <h6  class="name">经典文学</h6> 
            <small  class="desc">Classical Literature</small> 
            <i  class="more-icon"></i>
            </div> 
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/img_jingdianwenxue.87a4453.jpg')" alt="经典文学" />
            </div></a>
            <a  href="#" target="_self" class="cate-item">
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/img_shici.9ecc13a.jpg')" alt="诗词" />
            </div> 
            <div  class="cont">
            <h6  class="name">诗词</h6> 
            <small  class="desc">Poetry</small> 
            <i  class="more-icon"></i>
            </div> 
            <!----></a>
            <a  href="#" target="_self" class="cate-item">
            <!----> 
            <div  class="cont">
            <h6  class="name">艺术</h6> 
            <small  class="desc">Art</small> 
            <i  class="more-icon"></i>
            </div> 
            <div  class="img-thumbnail">
            <img  :src="require('./../../public/page3/img_yishu.ff6cec0.jpg')" alt="艺术" />
            </div></a>
            </div> 
            <div  class="clear-both"></div>
        </div>
        <div  class="pcAtrcontent-item theaterSheet">
            <div  class="cate-group">
            <a  href="#" target="_self" class="cate-item"><img  :src="require('./../../public/page3/theater_cate.15093ed.jpg')"  /></a>
            <a  href="#" target="_self" class="cate-item"><img  :src="require('./../../public/page3/musicScore_cate.be61042.jpg')"  /></a>
            </div> 
            <div  class="clear-both"></div>
        </div>
        <div  class="pcAtrcontent-item vienna">
            <a  href="#" target="_blank" class="cate-group">
            <!----> <div  class="cate-item"><img  :src="require('./../../public/page3/547f3de8cdef45698732ddad367617d7.jpg')" alt="K+现场"> <p  class="cate-desc"><span >2021-01-29 19:30:00</span> <span >即将开始</span></p></div>
            <div  class="cont">
            <h6  class="name">K+现场</h6> 
            <p  class="desc">与世界著名古典艺术演出零距离<br />尽享音乐盛宴</p> 
            <i  class="more-icon"></i>
            </div></a> 
            <div  class="clear-both"></div>
        </div>
        </div><Footers></Footers>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Headers from './../components/header/header.vue';
import Footers from './../components/footer/footer.vue';
export default {
//import引入的组件需要注入到对象中才能使用
 components: {Headers,Footers},
data() {
//这里存放数据
return {

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@import url("./../../public/page3/index.css");

.pcAtrcontent {
    padding: 169px 0 50px;
    
}
@media screen and (min-width: 768px) and (max-width: 991px){
    body .pcAtrcontent {
        padding: 119px 0 33px;
    }
}
@media screen and (max-width: 767px){
    body .pcAtrcontent {
        padding: 0 0 21px;
        
    }
}

.cate-item{
    transition: all 0.6s;  
}
.cate-item:hover{
    transform: scale(1.1);
}
.pcAtrcontent .vienna .cate-group{
     .name{
        position: relative;
        font-size: 30px;
        color: #331514;
        font-weight: 400;
        height: 40px;
        background-color: #fff;
        white-space: nowrap;
    }
    .name:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 28px;
        height: 1px;
        background-color: #331514;
    }
    .desc{
        display: block;
        font-size: 14px;
        color: #331514;
    }
}
</style>