<!--  -->
<template>
  <div class="">
    <Headers></Headers>
    <div class="wd1200">
      <div class="case_main">
        <div class="case_para">
          <p class="font36">集团子公司</p>
          <p class="strike"></p>
          <div class="case_sec">
            <div class="case_aside">
              <p class="font24"><span></span>大莹服装电商城</p>
              <p class="us_word">
                大莹服装电商城自2008年起专注于O2O”专业市场模式，即实体营销+网络营销的营销模式。目前已发展成为集服装网络供货、实体网络销售为一体的专业服装市场，被评定为“广东省电子商务示范园区”及阿里全国“电子商务园区十佳创新奖”获得者。
              </p>
            </div>
            <div class="case_pic2">
              <img :src="require('./../../public/subsidiary/daying.png')" />
            </div>
          </div>
          <div class="case_sec">
            <div class="case_aside">
              <p class="font24"><span></span>东莞誉尚服饰有限公司</p>
              <p class="us_word">
                由美国ATXG集团子公司深圳市前海盈喜产业链服务有限公司投资的一家专业从事服装研发、生产及销售的公司
              </p>
            </div>
            <div class="case_pic2">
              <img :src="require('./../../public/page2/case_2.jpg')" />
            </div>
          </div>
          <div class="case_sec">
            <div class="case_aside case_aside2">
              <p class="font24"><span></span>深圳市盈喜鹏发物流有限公司</p>
              <p class="us_word">
                华鹏发物流有限公司成立于1996年，是一家具有二十年运输经验的专线物流公司，专业承接深圳往返福建、潮汕、珠三角、惠州等地运输业务。
              </p>
            </div>
            <div class="case_pic1">
              <img :src="require('./../../public/page2/case_3.jpg')" />
            </div>
          </div>
          <div class="case_sec">
            <div class="case_aside case_aside2">
              <p class="font24"><span></span>深圳市新快捷运输有限公司</p>
              <p class="us_word">
                深圳市新快捷运输有限公司是一家专业平板运输、集装箱运输、报关一条龙服务的运输企业，是深圳市集装箱拖车运输协会的会员单位。公司于2001年经深圳市工商局、交通局比准成立，注册资本为1000万元人民币。公司总部设在盐田，蛇口设有分公司，主要承接全国长途运输、进出口运输、散货入仓及报关等业务。
              </p>
            </div>
            <div class="case_pic1">
              <img :src="require('./../../public/page2/case_3.jpg')" />
            </div>
          </div>
          <!-- <div class="case_other">
            <ol class="case_ol cf">
              <li>山西省图书馆</li>
              <li>珠海市文化馆</li>
              <li>马鞍山市文化馆</li>
              <li>天津市河西区文化馆</li>
            </ol>
            <ol class="case_ol cf">
              <li>陕西省艺术馆</li>
              <li>黑龙江省群众艺术馆</li>
              <li>无锡高新区（新吴区）文化馆</li>
            </ol>
          </div> -->
        </div>
        <!-- <div class="case_para">
          <p class="font36">学校类</p>
          <p class="strike"></p>
          <div class="case_sec">
            <div class="case_aside case_aside2">
              <p class="font24">
                <span></span
                >哈尔滨工业大学<br />&nbsp;&nbsp;&nbsp;&nbsp;沈阳师范大学
              </p>
              <p class="us_word">
                KUKEY智能钢琴在大学中主要以自由学习的形式存在，在图书馆等公共区域，方便学生自由弹奏和欣赏学习，丰富了大学艺术活动类型。
              </p>
            </div>
            <div class="case_pic1">
              <img :src="require('./../../public/page2/case_4.jpg')" />
            </div>
          </div>

          <div class="case_sec">
            <div class="case_aside">
              <p class="font24"><span></span>福州八中</p>
              <p class="us_word">
                福建省教育系统的领导分批参观了库客音乐教室，并给与很高的评价。肯定了库客音乐在教育信息化进程中做出的贡献。福建省教育厅副厅长，电教馆馆长，教学仪器装备站领导均参观库客音乐教室后给以高度评价。
              </p>
            </div>
            <div class="case_pic3">
              <img :src="require('./../../public/page2/case_5.jpg')" />
            </div>
          </div>

          <div class="case_sec">
            <div class="case_aside">
              <p class="font24"><span></span>天津金钟小学</p>
              <p class="us_word">
                以提升学生钢琴弹奏能力为目的，校方在经过考察KUKEY教学系统与卡西欧电钢琴后，认为产品功能和教学理念等非常适用于目前的中小学钢琴教育，有利于钢琴教育的普及。
              </p>
            </div>
            <div class="case_pic3">
              <img :src="require('./../../public/page2/case_6.jpg')" />
            </div>
          </div>
          <div class="case_other">
            <ol class="case_ol cf">
              <li>北京师范大学</li>
              <li>南京艺术学院</li>
              <li>天津丽泽小学</li>
              <li>沈阳太阳小学</li>
            </ol>
            <ol class="case_ol cf">
              <li>沈阳师范大学</li>
              <li>广西北海中等职业学校</li>
              <li>天津军粮城小学</li>
              <li>重庆高滩岩小学</li>
            </ol>
            <ol class="case_ol cf">
              <li>辽宁师范大学</li>
              <li>江苏扬州商贸旅游学校</li>
              <li>天津华明小学</li>
              <li>成都金花小学</li>
            </ol>
            <ol class="case_ol cf">
              <li>南京师范大学</li>
              <li>北京清华附中</li>
              <li>天津滨瑕实验小学</li>
              <li>福州第八中学</li>
            </ol>
            <ol class="case_ol cf">
              <li>青海师范大学</li>
              <li>天津金钟小学</li>
              <li>天津北辰小学</li>
              <li>四川大学</li>
            </ol>
          </div>
        </div> -->
      </div>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Headers from './../components/header/header.vue';
import Footers from './../components/footer/footer.vue';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  data () {
    //这里存放数据
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
@import url("./../../public/page2/init.css"); //引入公共css类
</style>
